import React, { useEffect } from "react"
import Container from "react-bootstrap/Container"

import { itunesLink, googlePlayLink } from "../constants/links"

/* Images */
import NavBarLogo from "../images/logo.svg"

/* Components */
import Wrapper from "../components/wrapper/wrapper"
import SEO from "../components/seo/seo"
import NavBar from "../components/navbar/navbar"
import Main from "../components/main/main"
import Loading from "../components/loading/loading"
import Footer from "../components/footer/footer"

export default function App({ location }) {
  useEffect(() => {
    var TIMEOUT = 1 * 1000 // 1 second
    var ua = navigator.userAgent.toLowerCase()
    var params = window.location.search
    var defaultLink = "https://www.gasolinamovil.com"
    function redirect(target) {
      setTimeout(function () {
        window.location = target
      }, TIMEOUT)
    }
    if (ua.indexOf("android") > -1) {
      // If params = concat
      if (params) {
        var newAndroidLink = googlePlayLink.concat(params.replace("?", "&"))
        redirect(newAndroidLink)
      } else {
        redirect(googlePlayLink)
      }
    } else if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1) {
      // If params = concat & replace ?
      if (params) {
        var newIosLink = itunesLink.concat(params.replace("?", "&"))
        redirect(newIosLink)
      } else {
        redirect(itunesLink)
      }
    } else {
      redirect(defaultLink)
    }
  }, [])

  return (
    <Wrapper>
      <SEO />
      <NavBar classVariant="navBarDefault" logo={NavBarLogo} />
      <Main>
        <Container>
          <Loading />
        </Container>
      </Main>
      <Footer />
    </Wrapper>
  )
}
